<script>
    import { mapState } from "vuex";
    import ThePersonalUserCard from "@/ThePersonal/ThePersonalUserCard";

    export default {
        name: "ThePersonalData",
        components: { ThePersonalUserCard },
        data() {
            return {
                isLoadedUser: false,
            };
        },
        computed: {
            ...mapState("Profile", ["user"]),
            userString() {
                return JSON.stringify(this.user);
            },
        },
        watch: {
            userString(val) {
                if (val) {
                    this.isLoadedUser = true;
                }
            },
        },
        mounted() {
            if (Object.values(this.user).length) {
                this.isLoadedUser = true;
            }
        },
    };
</script>

<template>
    <section class="the-personal-data">
        <the-personal-user-card v-if="isLoadedUser" :user="user"></the-personal-user-card>
    </section>
</template>
