<script>
    import ThePersonalInput from "@/ThePersonal/components/ui/ThePersonalInput";

    export default {
        name: "ThePersonalInputPhone",
        components: { ThePersonalInput },
        props: {
            value: String,
            error: String,
        },
        data() {
            return {
                focused: false,
                currentValue: this.value,
                elementInput: "",
            };
        },
        watch: {
            value: {
                handler(newVal) {
                    this.currentValue = this.validate(newVal);
                    this.$emit("input", this.validate(newVal));
                },
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.elementInput = this.$refs.jsInputPhone.$el.querySelector("input");
                this.updateElement();
            });
        },
        methods: {
            getCursorPos() {
                let input = this.elementInput,
                    r,
                    re,
                    rc,
                    pos;

                if (input.selectionStart) {
                    pos = input.selectionStart;
                } else if (document.selection) {
                    input.focus();

                    r = document.selection.createRange();
                    if (r === null || typeof r === "undefined") {
                        pos = 0;
                    } else {
                        re = input.createTextRange();
                        rc = re.duplicate();
                        re.moveToBookmark(r.getBookmark());
                        rc.setEndPoint("EndToStart", re);

                        pos = rc.text.length;
                    }
                } else {
                    pos = 0;
                }

                return pos;
            },

            updateElement() {
                this.elementInput.value = this.validate(this.elementInput.value);
                this.currentValue = this.elementInput.value;
                this.$emit("input", this.elementInput.value);
            },
            focusElement() {
                this.elementInput.value = this.validate(this.elementInput.value);
                this.currentValue = this.elementInput.value;
                this.focused = true;
                this.$emit("focus");
            },
            blurElement() {
                if (this.elementInput.value.length === 2) {
                    this.currentValue = "";
                }
                this.focused = false;
            },
            validate(value) {
                let matrix = "+7 (___) ___-__-__",
                    i = 0,
                    def = matrix.replace(/\D/g, ""),
                    val = value.replace(/\D/g, "");

                if (def.length >= val.length) val = def;

                value = matrix.replace(/./g, function (a) {
                    return /[_\d]/.test(a) && i < val.length
                        ? val.charAt(i++)
                        : i >= val.length
                        ? ""
                        : a;
                });
                return value;
            },
        },
    };
</script>

<template>
    <the-personal-input
        ref="jsInputPhone"
        v-model="currentValue"
        type="tel"
        name="phone"
        :error="error"
        @focus="focusElement"
        @blur="blurElement"
        @input="updateElement"
    ></the-personal-input>
</template>
