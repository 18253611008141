<script>
    import { mapActions } from "vuex";
    import { apiUrl } from "@/utils";
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalSelect from "@/ThePersonal/components/ui/ThePersonalSelect";
    import ThePersonalInput from "@/ThePersonal/components/ui/ThePersonalInput";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";
    import ThePersonalInputPhone from "@/ThePersonal/components/ui/ThePersonalInputPhone";
    import ThePersonalUserAvatar from "@/ThePersonal/ThePersonalUserAvatar";

    const ERRORS = {
        length: "Поле обязательно для заполнения",
        cyrillic: "Используйте только кириллические символы",
    };

    export default {
        name: "ThePersonalUserCard",
        components: {
            ThePersonalUserAvatar,
            ThePersonalInputPhone,
            ThePersonalInput,
            ThePersonalSelect,
            ThePersonalButton,
            ThePersonalTooltip,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    photo: {
                        small: this.user.photo ? apiUrl(this.user.photo?.small) : "",
                    },
                    name: this.user.name,
                    lastName: this.user.lastName,
                    birthday: this.user.birthday || "",
                    gender: this.user.gender,
                    email: this.user.email,
                    phone: "+7 " + this.user.phone,
                },
                formData: null,
                error: {
                    name: "",
                    lastName: "",
                    email: "",
                    phone: "",
                },
                isEdited: false,
                isSelectedBirthday: false,
            };
        },
        computed: {
            fullName() {
                return `${this.form.name} ${this.form.lastName}`;
            },
            photoSrc() {
                if (this.form.photo.small) {
                    return this.form.photo.small;
                }
                return false;
            },
            days() {
                const arr = [];
                const startDay = 1;
                const endDay = 31;
                for (let i = startDay; i <= endDay; i++) {
                    arr.push({ name: i, value: i < 10 ? `0${i}` : i });
                }
                return arr;
            },
            months() {
                return [
                    { name: "января", value: "01" },
                    { name: "февраля", value: "02" },
                    { name: "марта", value: "03" },
                    { name: "апреля", value: "04" },
                    { name: "мая", value: "05" },
                    { name: "июня", value: "06" },
                    { name: "июля", value: "07" },
                    { name: "августа", value: "08" },
                    { name: "сентября", value: "09" },
                    { name: "октября", value: "10" },
                    { name: "ноября", value: "11" },
                    { name: "декабря", value: "12" },
                ];
            },
            years() {
                const arr = [];
                const startYear = 1900;
                const currentYear = new Date().getFullYear();
                for (let i = startYear; i <= currentYear; i++) {
                    arr.push({ name: i, value: i });
                }
                return arr;
            },
            initialGenderValue() {
                if (this.form.gender) {
                    return this.gender.find((g) => this.form.gender === g.value).name;
                }
                return "Не указан";
            },
            gender() {
                const genders = [
                    { name: "Женский", value: "F" },
                    { name: "Мужской", value: "M" },
                ];
                if (!this.form.gender) {
                    genders.unshift({ name: "Не указан", value: "" });
                }
                return genders;
            },
            birthday() {
                if (this.form.birthday) {
                    const [day, monthValue, year] = this.form.birthday.split(".");
                    const month = this.months.find((m) => m.value === monthValue).name;
                    return { day: day < 10 ? day.replace("0", "") : day, month, year };
                }
                return { day: "01", month: "01", year: "1900" };
            },
            isDisabledBirthdaySelect() {
                return this.user.birthday?.length > 0;
            },
            isDisabledSubmitButton() {
                return !this.isEdited || Object.values(this.error).some((e) => e.length);
            },
            formString() {
                const phone = this.transformPhoneValue(this.form.phone);
                return JSON.stringify({ ...this.form, phone });
            },
        },
        watch: {
            formString(val, oldVal) {
                if (val !== oldVal) {
                    this.isEdited = true;
                }
            },
            "form.name"(val) {
                this.validation(val, "name");
            },
            "form.lastName"(val) {
                this.validation(val, "lastName");
            },
            "form.email"(val) {
                this.validation(val, "email");
            },
            "form.phone"(val) {
                this.validation(val, "phone");
            },
        },
        mounted() {
            if (this.form.birthday) {
                this.isSelectedBirthday = true;
            }
        },
        methods: {
            ...mapActions("Profile", ["updateProfileUser", "updateProfileUserPhoto"]),
            updateProfileUserHandler(payload) {
                this.updateProfileUser(payload).then((error) => {
                    if (error) {
                        this.error.name = error.name || "";
                        this.error.lastName = error.lastName || "";
                        this.error.phone = error.phone || "";
                        this.error.email = error.email || "";
                    }
                });
            },
            submitHandler() {
                const payload = { ...this.form };
                delete payload.photo;
                payload.phone = this.transformPhoneValue(payload.phone);
                this.updateProfileUserHandler(payload);
                this.updateProfileUserPhoto(this.formData);

                this.isEdited = false;
            },
            updateValueFromSelect({ option, type }) {
                if (type === "gender") {
                    this.form[type] = option.value;
                } else {
                    const [day, month, year] = this.form.birthday.split(".");
                    const date = { day, month, year };
                    date[type] = option.value;
                    this.form.birthday = Object.values(date).join(".");
                }
            },
            changePhotoHandler({ target }) {
                this.formData = new FormData();
                this.formData.append("photo", target.files[0]);

                const fileReader = new FileReader();
                fileReader.onload = () => {
                    this.form.photo.small = fileReader.result;
                };
                fileReader.readAsDataURL(target.files[0]);
            },
            transformPhoneValue(val) {
                return val.replace(/[^a-zA-Z0-9]/g, "");
            },
            validation(value, field) {
                this.error[field] = "";
                if (!value.trim().length) {
                    this.error[field] = ERRORS.length;
                }
                if (!["email", "phone"].includes(field)) {
                    if (value.length && !/^[а-яА-ЯЁё\s]+$/.test(value)) {
                        this.error[field] = ERRORS.cyrillic;
                    }
                }
            },
            setEditableBirthday() {
                this.isSelectedBirthday = true;
                this.form.birthday = "01.01.1900";
            },
        },
    };
</script>

<template>
    <div class="the-personal-user-card">
        <div class="the-personal-user-card__header">
            <div class="the-personal-user-card__photo">
                <img v-if="photoSrc" :src="photoSrc" alt="" />
                <the-personal-user-avatar
                    v-else
                    :name="user.name"
                    :last-name="user.lastName"
                ></the-personal-user-avatar>
            </div>
            <div>
                <div class="the-personal-user-card__name">
                    {{ fullName }}
                </div>
                <form @submit.prevent ref="photoForm">
                    <label class="the-personal-user-card__edit">
                        <input
                            class="the-personal-user-card__edit-input visually-hidden"
                            type="file"
                            name="photo"
                            accept=".png, .jpg, .jpeg"
                            @change="changePhotoHandler"
                        />
                        <span class="the-personal-user-card__edit-text">
                            Редактировать фото профиля
                        </span>
                    </label>
                </form>
            </div>
        </div>
        <form class="the-personal-user-card__form" @submit.prevent>
            <div class="the-personal-user-card__col">
                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption">Имя</span>
                    <the-personal-input
                        class="the-personal-user-card__input"
                        type="text"
                        name="name"
                        v-model="form.name"
                        :error="error.name"
                        placeholder=" "
                    >
                    </the-personal-input>
                </div>
                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption">Фамилия</span>
                    <the-personal-input
                        class="the-personal-user-card__input"
                        type="text"
                        v-model="form.lastName"
                        :error="error.lastName"
                        placeholder=" "
                    ></the-personal-input>
                </div>

                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption centered">Дата рождения</span>
                    <span v-if="isSelectedBirthday" class="the-personal-user-card__group">
                        <the-personal-select
                            class="the-personal-user-card__select"
                            :options="days"
                            type="day"
                            :value="birthday.day"
                            :disabled="isDisabledBirthdaySelect"
                            @input="updateValueFromSelect"
                        ></the-personal-select>
                        <the-personal-select
                            class="the-personal-user-card__select"
                            :options="months"
                            type="month"
                            :value="birthday.month"
                            :disabled="isDisabledBirthdaySelect"
                            @input="updateValueFromSelect"
                        ></the-personal-select>
                        <the-personal-select
                            class="the-personal-user-card__select"
                            :options="years"
                            type="year"
                            :value="birthday.year"
                            :disabled="isDisabledBirthdaySelect"
                            @input="updateValueFromSelect"
                        ></the-personal-select>
                    </span>
                    <button
                        v-else
                        class="the-personal-user-card__birthday outline"
                        @click="setEditableBirthday"
                    >
                        Не указана
                        <span class="the-personal-user-card__icon">
                            <img src="@/ThePersonal/assets/icons/tooltip-info.svg" alt="" />
                            <span class="the-personal-user-card__tooltip">
                                <the-personal-tooltip width="280">
                                    Дополнительная скидка в день рождения! <br />
                                    Обращаем внимание, что дату рождения можно ввести только
                                    один раз. Для изменения даты необходимо написать в
                                    поддержку
                                    <a href="mailto:bd@madrobots.ru">bd@madrobots.ru</a>
                                </the-personal-tooltip>
                            </span>
                        </span>
                        <span class="the-personal-user-card__notification">
                            Дополнительная скидка в день рождения! <br />
                            Обращаем внимание, что дату рождения можно ввести только один раз.
                            Для изменения даты необходимо написать в поддержку
                            <a href="mailto:bd@madrobots.ru">bd@madrobots.ru</a>
                        </span>
                    </button>
                </div>
                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption centered">Пол</span>
                    <the-personal-select
                        class="the-personal-user-card__select"
                        :options="gender"
                        :value="initialGenderValue"
                        type="gender"
                        @input="updateValueFromSelect"
                    ></the-personal-select>
                </div>
            </div>
            <div class="the-personal-user-card__col">
                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption">Почта</span>
                    <the-personal-input
                        class="the-personal-user-card__input"
                        type="email"
                        name="email"
                        v-model="form.email"
                        :error="error.email"
                        placeholder=" "
                    ></the-personal-input>
                </div>
                <div class="the-personal-user-card__row">
                    <span class="the-personal-user-card__caption">Телефон</span>
                    <the-personal-input-phone
                        class="the-personal-user-card__input"
                        type="tel"
                        :value="form.phone"
                        :error="error.phone"
                        @input="(e) => (form.phone = e)"
                        placeholder=" "
                    ></the-personal-input-phone>
                </div>
                <the-personal-button
                    class="the-personal-user-card__button"
                    :disabled="isDisabledSubmitButton"
                    @click="submitHandler"
                >
                    Сохранить изменения
                </the-personal-button>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
    .the-personal-user-card {
        padding: 15px;
        box-shadow: $personal-common-shadow-min;
        border-radius: $personal-box-radius;
        border: $personal-common-border-full;
        @include bp($bp-desktop-sm) {
            padding: 25px;
            box-shadow: $personal-common-shadow-max;
        }
    }

    .the-personal-user-card__header {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        border-color: $personal-divider-fill;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-image: var(--border-image-gradient);
        border-image-slice: 1;

        @include bp($bp-desktop-sm) {
            padding-bottom: 30px;
        }
    }

    .the-personal-user-card__photo {
        width: 78px;
        height: 78px;
        margin-right: 12px;
        border-radius: 50%;
        overflow: hidden;
        @include bp($bp-desktop-sm) {
            width: 100px;
            height: 100px;
            margin-right: 35px;
        }
    }

    .the-personal-user-card__name {
        margin-bottom: 5px;
        color: #40404b;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 23px;
        }
    }

    .the-personal-user-card__edit {
        color: $personal-accent-color;
        font-size: 14px;
        line-height: 16px;
        @include bp($bp-desktop-sm) {
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
        }
    }

    .the-personal-user-card__edit-input {
        @include bp($bp-desktop-sm) {
            &:focus-visible {
                & + .the-personal-user-card__edit-text {
                    @include outline;
                }
            }
        }
    }

    .the-personal-user-card__form {
        padding-top: 20px;
        @include bp($bp-desktop-sm) {
            display: flex;
            margin: 0 -20px;
            padding-top: 30px;
        }
    }

    .the-personal-user-card__col {
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            max-width: 50%;
            padding: 0 20px;
        }
    }

    .the-personal-user-card__row {
        display: block;
        margin-bottom: 15px;
        @include bp($bp-desktop-sm) {
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include bp($bp-desktop-md) {
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
        }
    }

    .the-personal-user-card__caption {
        display: block;
        margin-bottom: 2px;
        padding-left: 15px;
        color: #8f90a6;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        @include bp($bp-desktop-md) {
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 5px;
            padding-left: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: right;
            &.centered {
                align-self: center;
            }
        }
    }

    .the-personal-user-card__input {
        @include bp($bp-desktop-sm) {
            max-width: 320px;
        }
    }

    .the-personal-user-card__select {
        flex-shrink: 0;
        @include bp($bp-desktop-sm) {
            max-width: 320px;
        }
    }

    .the-personal-user-card__group {
        display: flex;
        .the-personal-user-card__select {
            &:not(:last-child) {
                margin-right: 10px;
            }
            &:first-child {
                width: calc(30% - 10px);
            }
            &:nth-child(2) {
                width: calc(43% - 10px);
            }
            &:last-child {
                width: 27%;
            }
        }
        @include bp($bp-desktop-sm) {
            width: 100%;
            max-width: 320px;
        }
    }

    .the-personal-user-card__birthday {
        position: relative;
        padding: 12px 0 12px 15px;
        color: $personal-accent-color;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            max-width: 320px;
            width: 100%;
            padding: 13px 0;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .the-personal-user-card__icon {
        display: none;
        @include bp($bp-desktop-sm) {
            position: relative;
            display: block;
            margin-left: 12px;
            width: 24px;
            height: 24px;
            &:hover {
                .the-personal-user-card__tooltip {
                    display: block;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: -15px;
                    bottom: 0;
                    left: 0;
                    width: 200px;
                }
            }
        }
    }

    .the-personal-user-card__tooltip {
        display: none;
    }

    .the-personal-user-card__tooltip,
    .the-personal-user-card__notification {
        a {
            color: $personal-widget-link-color;
        }
    }

    .the-personal-user-card__notification {
        display: block;
        margin-top: 25px;
        margin-left: -15px;
        padding: 15px;
        color: #555770;
        font-size: 12px;
        line-height: 18px;
        background-color: #e1f6ff;
        border-radius: $personal-box-radius--small;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-personal-user-card__button {
        @include bp($bp-desktop-sm) {
            max-width: 320px;
            margin-top: auto;
            margin-left: auto;
        }
    }
</style>
