<script>
    export default {
        name: "ThePersonalUserAvatar",
        props: {
            name: {
                type: String,
                default: "",
            },
            lastName: {
                type: String,
                default: "",
            },
            isSmall: Boolean,
        },
        computed: {
            symbols() {
                return (
                    (this.name.length ? this.name[0] : "") +
                    (this.lastName.length ? this.lastName[0] : "")
                );
            },
        },
    };
</script>

<template>
    <div class="the-personal-user-avatar">
        <div class="the-personal-user-avatar__text" :class="{ 'is-small': isSmall }">
            {{ symbols }}
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-user-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $personal-avatar-fill;
    }

    .the-personal-user-avatar__text {
        color: $personal-avatar-color;
        font-size: $personal-avatar-size;
        line-height: $personal-avatar-size;
        font-weight: $personal-avatar-weight;
        text-transform: uppercase;
        &.is-small {
            font-size: 11px;
            line-height: 11px;
        }
    }
</style>
